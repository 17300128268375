import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/store.js'
import Login from '../views/Login.vue'
import Search from '../views/Search.vue'
import BookForm from '../views/BookForm.vue'
import ShelveForm from '../views/ShelveForm.vue'
import BookList from '../views/BookList.vue'

const checkIsAuthenticated = (to, from, next) => {
  if (store.state.username) {
    next()
  } else {
    console.log('redirigido a /login')
    next('/login')
  }
}

const checkNotAuthenticated = (to, from, next) => {
  if (!store.state.username) {
    next()
  } else {
    console.log('redirigido a /')
    next('/')
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: BookList,
    beforeEnter: checkIsAuthenticated,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: checkNotAuthenticated,
  },
  {
    path: '/search/:type',
    name: 'Search',
    component: Search,
    props: true,
    beforeEnter: checkIsAuthenticated,
  },
  // BOOKS
  {
    path: '/books',
    name: 'BookList',
    component: BookList,
    beforeEnter: checkIsAuthenticated,
  },
  {
    path: '/books/add',
    name: 'AddBook',
    component: BookForm,
    props: true,
    beforeEnter: checkIsAuthenticated,
  },
  {
    path: '/books/edit/:id',
    name: 'EditBook',
    component: BookForm,
    props: true,
    beforeEnter: checkIsAuthenticated,
  },
  {
    path: '/shelves',
    name: 'ShelveForm',
    component: ShelveForm,
    props: true,
    beforeEnter: checkIsAuthenticated,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
