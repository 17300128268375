<template>
  <section class="section">
    <div class="container is-max-desktop">
      <!-- BEGIN FILTERS -->
      <div class="columns is-multiline is-mobile">
        <!-- SEARCH -->
        <div class="column is-12-mobile is-3-tablet">
          <div class="control">
            <input v-model="search" id="search" class="input" type="text" placeholder="Search..." />
          </div>
        </div>

        <!-- STATUS -->
        <div class="column is-6-mobile is-3-tablet">
          <div class="field is-grouped">

            <div class="control">
            <div class="select is-fullwidth">
              <select id="status" v-model="status">
                <option value="0">All statuses</option>
                <option v-for="status in statuses" :key="status.id" :value="status.id">
                  {{ status.name }}
                </option>
                <option value="_">No status</option>
              </select>
            </div>
            </div>

            <div class="control">
              <a @click="onClearFilters" class="button is-link is-info">Clear</a>
            </div>

          </div>
        </div>
      </div>
      <!-- END FILTERS -->

      <!-- TAGS -->
      <div class="tags are-medium">
        <span class="tag is-clickable is-info" @click="onUntagged()"
          >Untagged</span
        >
        <span
          :class="tagClasses(tag)"
          v-for="tag in shelves"
          :key="tag.id"
          @click="onClickTag(tag)"
        >
          {{ tag.name }}
        </span>
        <span class="tag is-clickable is-info is-light" @click="onEditTags()"
          >Edit</span
        >
      </div>

      <!-- ITEMS -->
      <div class="columns is-multiline is-mobile">
        <div
          v-for="item in filteredItems"
          :key="item"
          class="column is-4-mobile is-one-fifth-tablet is-2-desktop"
        >
          <Item
            :item="item"
            :show-status="status == 0"
            :statuses="statuses"
            @click="showStatusDlg(item)"
          />
        </div>
      </div>

      <!-- ADD BUTTON -->
      <router-link :to="{ name: 'Search', params: { type: 'book' } }">
        <div class="floating-btn">
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
        </div>
      </router-link>

      <!-- MODAL -->
      <StatusDlg
        ref="dialog"
        :statuses="statuses"
        edit-form="EditBook"
        @on-close="handleDialogCancel()"
        @on-save="handleDialogSave"
      />
    </div>
  </section>
</template>

<script>
import * as Config from '@/config/Config.js'
import * as Book from '@/context/Book.js'
import Item from '@/components/Item'
import StatusDlg from '@/components/StatusDlg'
const axios = require('axios')

export default {
  name: 'BookList',

  components: {
    Item,
    StatusDlg,
  },

  data() {
    return {
      shelves: [],
      items: [],
      search: '',
      status: localStorage.bookStatus ? localStorage.bookStatus : 0,
      shelve: localStorage.bookType ? localStorage.bookType : 0,
      statuses: Book.statuses,
      mapTags: new Map(),
    }
  },

  mounted() {
    this.loadShelves()
    this.loadItems()
    
    if (this.getSavedTags()) {
      this.mapTags = new Map(JSON.parse(this.getSavedTags()));
    } else {
      this.createMapTags();
    }
  },

  computed: {
    filteredItems() {
      return this.items
        .filter(
          (item) =>
            item.title.toUpperCase().includes(this.search.toUpperCase()) ||
            (item.author && item.author.toUpperCase().includes(this.search.toUpperCase()))
        )
        .filter(
          (item) =>
            this.status == 0 || item.status == this.status || (this.status == '_' && !item.status)
        )
        .filter(
          (item) =>
          this.getTagsShow().every((tag) => item.shelves.includes(tag)) &&
          item.shelves.every((tag) => !this.getTagsNotShow().includes(tag))
        )
    },
  },

  methods: {
    loadItems() {
      if (this.shelve == 0) {
        this.loadAllItems()
      } else if (this.shelve == 'U') {
        this.loadItemsUncategorized()
      } else {
        this.loadItemsByShelve(this.shelve)
      }
    },

    loadAllItems() {
      Book.getAll()
        .then((res) => {
          this.items = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    loadItemsUncategorized() {
      Book.getUncategorized()
        .then((res) => {
          this.items = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    loadItemsByShelve(shelve) {
      Book.getShelve(shelve)
        .then((res) => {
          this.items = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    loadShelves() {
      Book.getShelves()
        .then((res) => {
          this.shelves = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    onClearFilters() {
      this.search = ''
      this.status = 0
      this.createMapTags();
      this.saveTags();
    },

    handleShelveChange() {
      this.loadItems()
    },

    handleAddBook() {
      this.$router.push({ name: 'AddBook' })
    },

    showStatusDlg(item) {
      this.$refs.dialog.open(item)
    },

    handleDialogSave(status) {
      Book.postStatus(status)
        .then((res) => {
          this.$refs.dialog.close()
          this.loadItems()
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleDialogCancel() {
      this.$refs.dialog.close()
    },

    selectShelve(id) {
      this.shelve = id
      this.loadItems()
    },

    onClickTag(tag) {
      if (this.mapTags.get(tag.id) == 0) {
        this.mapTags.set(tag.id, 1);
      } else if (this.mapTags.get(tag.id) == 1) {
        this.mapTags.set(tag.id, -1);
      } else {
        this.mapTags.set(tag.id, 0);
      }
      this.saveTags();
    },

    onUntagged() {
      this.mapTags = new Map(this.shelves.map((tag) => [tag.id, -1]));
       this.saveTags();
    },

    onEditTags() {
      this.$router.push({
        name: "ShelveForm",
      });
    },

    createMapTags() {
      this.mapTags = new Map(this.shelves.map((tag) => [tag.id, 0]));
    },

    getTagsShow() {
      return [...this.mapTags].filter(([k, v]) => v == 1).map(([k, v]) => k);
    },

    getTagsNotShow() {
      return [...this.mapTags].filter(([k, v]) => v == -1).map(([k, v]) => k);
    },

    tagClasses(tag) {
      let color = "";
      if (this.mapTags.get(tag.id) == 1) {
        color = "is-primary";
      } else if (this.mapTags.get(tag.id) == -1) {
        color = "is-danger";
      }
      return ["tag", "is-clickable", color];
    },

    saveTags() {
      localStorage.tags = JSON.stringify([...this.mapTags]);
    },

    getSavedTags() {
      return localStorage.tags;
    },
  },

  watch: {
    status(newStatus) {
      localStorage.bookStatus = newStatus
    },
  },
}
</script>

<style>
#sidebar {
  /* background-color: #dddddd; */
  width: 150px;
  position: absolute;
  margin-top: 30px;
  padding-left: 10px;
}

@media (max-width: 1380px) {
  #sidebar {
    display: none;
  }
}

@media (min-width: 1380px) {
  #shelves-filter {
    display: none;
  }
}

.sidebar-item {
  padding: 10px 0;
}

.sidebar-item-selected {
  text-decoration: underline;
}

.sidebar-title {
  font-size: 2em;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.floating-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  box-shadow: 2px 2px 3px rgb(34, 34, 34);;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-btn span {
  font-size: 22px;
}
</style>
