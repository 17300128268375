import { createStore } from 'vuex'
const axios = require('axios')

const store = createStore({
  state: {
    username: localStorage.username || null,
  },
  mutations: {
    login(state, credentials) {
      state.username = credentials.username
    },
    logout(state) {
      state.username = null
    },
  },
  actions: {
    async login({ commit }, credentials) {
      localStorage.username = credentials.username
      localStorage.token = credentials.token
      axios.defaults.headers.common['Authorization'] = credentials.token
      commit('login', credentials)
    },
    async logout({ commit }) {
      localStorage.removeItem('username')
      localStorage.removeItem('token')
      delete axios.defaults.headers.common['Authorization']
      commit('logout')
    },
  },
})

export default store
