<template>
  <section class="section">
    <div class="container is-max-desktop">
      <div class="tag-row is-flex is-align-items-center" v-for="tag in shelves" :key="tag.id">
        <div v-if="editingTag && tag.id == editingTag.id" class="is-flex-grow-1">
          <input class="input" type="text" v-model="editingTag.name" @keydown.enter="onSave()" @keydown.esc="onCancel()" />
        </div>
        <div v-else class="name is-flex-grow-1" @click="onEdit(tag)">
          {{ tag.name }}
        </div>
        <div>
          <a class="button is-danger" style="margin-left: 5px;" @click="onDelete(tag)">Delete</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as Book from '@/context/Book.js'
import * as Config from '@/config/Config.js'
const axios = require('axios')

export default {
  data() {
    return {
      shelves: [],
      editingTag: null,
      name: '',
    }
  },

  mounted() {
    this.loadShelves()
  },

  methods: {
    loadShelves() {
      Book.getShelves()
        .then((res) => {
          this.shelves = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    onEdit(tag) {
      this.editingTag = tag
    },

    onCancel() {
      this.editingTag = null
    },

    onSave() {
      Book.updateShelve(this.editingTag.id, {
        name: this.editingTag.name
      })
      .then((res) => {
        this.loadShelves()
      })
      .catch((err) => {
        console.log(err)
      })
      this.editingTag = null
    },

    onDelete(shelve) {
      Book.deleteShelve(shelve.id)
        .then((res) => {
          this.loadShelves()
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style>
@media screen and (max-width: 768px) {
  .fw-mobile {
    width: 100%;
  }
}

.tag-row {
  padding: 10px;
  border-bottom: 1px solid #111;
}

.name {
  cursor: pointer;
}
</style>
