<template>
  <div>
    <div class="item" :title="item.title">
      <!-- IMAGE -->
      <!-- <img class="item-img" src="#" /> -->
      <img class="item-img" loading="lazy" :src="getImgUrl(item.image)" />
    </div>
    <!-- STATUS -->
    <div v-if="showStatus" class="status" :style="{ backgroundColor: statusColor }"></div>
  </div>
</template>

<script>
import * as Config from '@/config/Config.js'

export default {
  props: ['item', 'statuses', 'showStatus'],

  computed: {
    statusColor() {
      if (this.item.status) {
        return this.statuses.find((status) => status.id == this.item.status).color
      } else {
        return ''
      }
    },
  },

  methods: {
    getImgUrl(url) {
      if (!url || url.startsWith('http')) {
        return url
      } else {
        return Config.WS_URL_IMG + url
      }
    },
  },
}
</script>

<style>
.item {
  cursor: pointer;
  position: relative;
  padding-bottom: 130%;
}
.item-img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  box-shadow: 4px 4px 5px rgb(34, 34, 34);
}

.item-info {
  position: absolute;
  border-radius: 5%;
  background-color: rgba(54, 54, 54, 0.8);
  width: 100%;
  height: 100%;
  color: white;
  padding: 8px;
  display: none;
}

.item:hover > .item-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-title {
  cursor: pointer;
  font-size: 1.2rem;
  flex-grow: 1;
  width: 100%;
  text-align: center;
}

.item-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: white;
}

.item-btns .icon {
  cursor: pointer;
}

.item-btns a:hover {
  color: #3273dc;
}

.item-btns a {
  color: white;
}

.status {
  margin-top: 4px;
  height: 4px;
}
</style>
