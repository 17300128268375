<template>
  <div class="modal" :class="{ 'is-active': show }">
    <div class="modal-background" @click="$emit('onClose')"></div>
    <div class="modal-card card" v-if="item">
      <header class="modal-card-head card-header">
        <p class="modal-card-title">
          {{ shortenTitle(item.title) }}
        </p>
        <a v-if="false" :href="item.link" target="blank">
          <span class="icon is-medium">
            <i class="fab fa-goodreads fa-lg"></i>
          </span>
        </a>
        <router-link :to="{ name: editForm, params: { id: item.id } }" style="margin-left: 10px;">
          <span class="icon is-medium">
            <i class="fas fa-edit fa-lg"></i>
          </span>
        </router-link>
      </header>

      <div
        class="status-row"
        :class="{ active: selStatus == status.id }"
        v-for="status in statuses"
        :key="status.id"
        @click="handleStatusChange(status.id)"
      >
        {{ status.name }}
      </div>

      <footer class="card-footer">
        <a @click="handleSave()" class="card-footer-item">
          Save
        </a>
        <a @click="handleCancel()" class="card-footer-item">
          Cancel
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import * as Config from '@/config/Config.js'
import * as Book from '@/context/Book.js'
const axios = require('axios')

export default {
  props: ['statuses', 'editForm'],

  data() {
    return {
      show: false,
      selStatus: 0,
      item: null,
    }
  },

  methods: {
    shortenTitle(title) {
      if (title.length > 25) {
        return title.slice(0, 22).trim() + '...'
      } else {
        return title
      }
    },

    open(item) {
      this.show = true
      this.selStatus = item.status
      this.item = item
    },

    close() {
      this.show = false
    },

    handleStatusChange(id) {
      this.selStatus = id
    },

    handleSave() {
      this.$emit('onSave', {
        book_id: this.item.id,
        status: this.selStatus,
      })
    },

    handleCancel() {
      this.$emit('onClose')
    },
  },
}
</script>

<style>
.modal-card {
  max-width: 400px !important;
}

.status-row {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #999;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  background-color: #2b3e50;
  color: #dee5ed;
}

.status-row:hover {
  background-color: #3a546b;
}

.status-row.active {
  cursor: default;
  background-color: #3298dc;
  color: white;
}

.status-row.active:hover {
  background-color: rgb(58, 109, 204);
}


</style>
