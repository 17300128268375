<template>
  <section class="section">
    <div class="container is-max-desktop" id="search">
      <h1 class="title">Search</h1>

      <div class="columns is-multiline">
        <!-- NEW ITEM -->
        <div class="field column is-12">
          <a class="button is-info" @click="handleCreate">Create new</a>
        </div>
        <!-- GOODREADS -->
        <form @submit.prevent="handleSearchGoodreads">
          <div class="field column is-12 is-grouped">
            <div class="control is-expanded">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input v-model="gr" id="image" class="input" type="text" placeholder="Title" />
                </div>
                <div class="control">
                  <button type="submit" class="button is-info">
                    Search Goodreads
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- TITLE -->
        <form @submit.prevent="handleSearch">
          <div class="field column is-12 is-grouped">
            <div class="control is-expanded">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input v-model="title" id="image" class="input" type="text" placeholder="Title" />
                </div>
                <div class="control">
                  <button type="submit" class="button is-info">
                    Search Title
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- ISBN -->
        <form @submit.prevent="handleSearchISBN" v-if="type == 'book' || type == 'comic'">
          <div class="field column is-12 is-grouped">
            <div class="control is-expanded">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input v-model="isbn" id="image" class="input" type="text" placeholder="ISBN" />
                </div>
                <div class="control">
                  <button type="submit" class="button is-info">
                    Search ISBN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- END OF CONTROLS -->
      </div>

      <div class="results">
        {{ message }}

        <div class="result is-flex" v-for="res in results" :key="res.title" @click="handleResultClick(res)">
          <img :src="res.img" style="height: 80px" />
          <div style="margin-left: 10px">
            <div style="font-weight: bold">{{ res.title }}</div>
            <div>{{ res.subtitle }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as Config from '@/config/Config.js'
const axios = require('axios')

export default {
  props: ['type'],

  data() {
    return {
      title: '',
      gr: '',
      isbn: '',
      results: [],
      searching: false,
      message: '',
    }
  },

  mounted() {
    axios.get(Config.WS_SEARCH)
  },

  methods: {
    handleSearch() {
      this.searching = true
      this.message = 'Searching...'
      this.results = []

      axios
        .get(Config.WS_SEARCH + 'book/title?q=' + this.title)
        .then((res) => {
          console.log(res.data)
          this.results = res.data
          this.message = ''
        })
        .catch((err) => {
          console.log(err)
          this.message = 'There was an error'
        })
        .finally(() => {
          this.searching = false
        })
    },

    handleSearchGoodreads() {
      this.searching = true
      this.message = 'Searching...'
      this.results = []

      axios
        .get(Config.WS_SEARCH + 'book/search?q=' + this.gr)
        .then((res) => {
          console.log(res.data)
          this.results = res.data
          this.message = ''
        })
        .catch((err) => {
          console.log(err)
          this.message = 'There was an error'
        })
        .finally(() => {
          this.searching = false
        })
    },

    handleSearchISBN() {
      this.searching = true
      this.message = 'Searching...'
      this.results = []

      axios
        .get(Config.WS_SEARCH + this.type + '/isbn?q=' + this.isbn)
        .then((res) => {
          console.log(res.data)
          this.results = res.data
          this.message = ''
        })
        .catch((err) => {
          console.log(err)
          this.message = 'There was an error'
        })
        .finally(() => {
          this.searching = false
        })
    },

    handleResultClick(res) {
      let view = ''

      if (this.type == 'book') view = 'AddBook'
      if (this.type == 'game') view = 'AddGame'
      if (this.type == 'comic') view = 'AddComic'
      if (this.type == 'show') view = 'AddShow'
      if (this.type == 'anime') view = 'AddAnime'
      if (this.type == 'manga') view = 'AddManga'

      this.$router.push({
        name: view,
        params: {
          searchTitle: res.title,
          searchSubtitle: res.subtitle,
          searchImg: res.img,
          searchLink: res.link,
        },
      })
    },

    handleCreate() {
      let view = ''

      if (this.type == 'book') view = 'AddBook'
      if (this.type == 'game') view = 'AddGame'
      if (this.type == 'comic') view = 'AddComic'
      if (this.type == 'show') view = 'AddShow'
      if (this.type == 'anime') view = 'AddAnime'
      if (this.type == 'manga') view = 'AddManga'

      this.$router.push({ name: view })
    },
  },
}
</script>

<style scoped>
.result {
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.result:hover {
  background-color: #eee;
}
</style>
