<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item is-size-4 is-uppercase has-text-weight-bold" to="/">
        Biblio
      </router-link>

      <a
        role="button"
        :class="['navbar-burger', 'burger', burgerActive ? 'is-active' : '']"
        @click="burgerActive = !burgerActive"
      >
        <span />
        <span />
        <span />
      </a>
    </div>

    <div v-if="currentUser" :class="['navbar-menu', burgerActive ? 'is-active' : '']">
      <div class="navbar-start" @click="burgerActive = !burgerActive">
        <a v-if="currentUser" class="navbar-item" href="https://biblioapi.pblrz.com/static/biblio.apk">
          Download app
        </a>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">{{ currentUser ? currentUser : '-' }}</div>
        <a v-if="currentUser" class="navbar-item" @click="logout">
          Log out
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',

  data() {
    return {
      burgerActive: false,
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.username
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    },
  },
}
</script>

<style></style>
