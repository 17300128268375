import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
require('@/assets/main.scss')
const axios = require('axios')

const token = localStorage.token
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (!error.response || (error.response.status !== 401 && error.response.status !== 403)) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    store.dispatch('logout').then(() => {
      router.push('/login')
    })

    return new Promise((resolve, reject) => {
      reject(error)
    })
  }
)

createApp(App)
  .use(store)
  .use(router)
  .mount('#app')
