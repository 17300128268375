const axios = require('axios')
import * as Config from '@/config/Config.js'

export const statuses = [
  { id: 1, name: 'Reading', color: '#ffe417' },
  { id: 2, name: 'On hold', color: '#ffa617' },
  { id: 3, name: 'To read', color: '#ff1717' },
  { id: 4, name: 'Finished', color: '#17ff4d' },
  { id: 5, name: 'Dropped', color: '#a3a3a3' },
]

export const types = [
  { id: 1, name: 'Fiction' },
  { id: 2, name: 'Non-fiction' },
  { id: 3, name: 'Coffee Table' },
]

export const urlImg = 'https://duckduckgo.com/?t=h_&iax=images&ia=images&q=book+'

export async function getShelves() {
  return await axios.get(Config.WS_URL + '/books/shelves')
}

export async function getAll() {
  return await axios.get(Config.WS_URL + '/books')
}

export async function getUncategorized() {
  return await axios.get(Config.WS_URL + '/books/uncategorized')
}

export async function getShelve(id) {
  return await axios.get(Config.WS_URL + '/books/shelve/' + id)
}

export async function postShelve(data) {
  return await axios.post(Config.WS_URL + '/books/shelves', data)
}

export async function updateShelve(id, data) {
  return await axios.put(Config.WS_URL + '/books/shelves/' + id, data)
}

export async function deleteShelve(id) {
  return await axios.delete(Config.WS_URL + '/books/shelves/' + id)
}

export async function getOne(id) {
  return await axios.get(Config.WS_URL + '/books/' + id)
}

export async function del(id) {
  return await axios.delete(Config.WS_URL + '/books/' + id)
}

export async function post(data) {
  return await axios.post(Config.WS_URL + '/books/', data)
}

export async function put(id, data) {
  return await axios.put(Config.WS_URL + '/books/' + id, data)
}

export async function postStatus(data) {
  return await axios.post(Config.WS_URL + '/books/status/', data)
}
