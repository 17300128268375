<template>
  <section class="section">
    <div class="container is-max-desktop">
      <h1 class="title">{{ title }}</h1>

      <form @submit.prevent="handleAdd">
        <div class="columns is-mobile">

          <div class="column is-3">
            <img class="image" :src="image" />
          </div>

          <div class="column">

            <div class="field">
              <input
                class="input"
                type="text"
                v-model="title"
                placeholder="Title"
                required="required"
                :class="{ 'is-danger': hasError('title') }"
              />
            </div>

            <div class="field">
              <input
                class="input"
                type="text"
                v-model="author"
                placeholder="Author"
              />
            </div>

            <div class="field">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input v-model="link" id="link" class="input" type="text" />
                </div>
                <div class="control">
                  <a class="button is-info" :href="link" target="blank">Go</a>
                </div>
              </div>
            </div>

            <div class="field">
              <input
                id="image"
                class="input"
                type="text"
                v-model="image"
                placeholder="Image"
              />
            </div>

            <div class="field is-grouped">
              <!-- SEARCH IMAGE -->
              <div class="control">
                <button class="button is-info" @click.prevent="handleSearch()" :disabled="searching">Search</button>
              </div>
              <!-- PREV IMAGE -->
              <div class="control">
                <button class="button is-info" @click.prevent="handleNextImg()" :disabled="results.length == 0">
                  <span class="icon is-small">
                    <i class="fas fa-arrow-left"></i>
                  </span>
                </button>
              </div>
              <!-- NEXT IMAGE -->
              <div class="control">
                <button class="button is-info" @click.prevent="handlePrevImg()" :disabled="results.length == 0">
                  <span class="icon is-small">
                    <i class="fas fa-arrow-right"></i>
                  </span>
                </button>
              </div>
            </div>
          

          <!-- ADD TAG -->
          <form @submit.prevent="onAddShelve">
            <div class="field is-grouped">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Add shelve"
                  v-model="newShelve"
                />
              </div>
              <div class="control">
                <button class="button is-link">Add</button>
              </div>
            </div>
          </form>

          <!-- TAGS -->
          <div class="tags are-medium">
            <span
              :class="shelveClasses(shelve)"
              v-for="shelve in shelves"
              :key="shelve.id"
              @click="onClickShelve(shelve)"
            >
              {{ shelve.name }}
            </span>
          </div>

          <!-- BUTTONS -->
          <div class="field is-grouped buttons">
            <div class="control">
              <a @click="handleCancel" class="button is-link is-light">Cancel</a>
            </div>
            <div class="control" v-if="id != 'new'">
              <a @click="handleDelete" class="button is-danger">Delete</a>
            </div>
            <div class="control">
              <button type="submit" class="button is-link">Save</button>
            </div>
          </div>

        </div>

          <!-- END OF CONTROLS -->
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import * as Book from '@/context/Book.js'
import * as Config from '@/config/Config.js'
const axios = require('axios')

export default {
  props: ['id', 'searchTitle', 'searchSubtitle', 'searchImg', 'searchLink'],

  data() {
    return {
      shelves: [],
      selShelves: new Set(),
      newShelve: '',
      urlImg: Book.urlImg,
      errors: [],
      statuses: Book.statuses,
      title: null,
      author: null,
      image: null,
      link: null,
      status: localStorage.bookStatus > 0 ? localStorage.bookStatus : 1,
      owned: false,
      searching: false,
      results: [],
      currentResult: 0,
    }
  },

  mounted() {
    this.loadShelves()

    if (this.searchTitle) {
      this.title = this.searchTitle
      this.author = this.searchSubtitle
      this.image = this.searchImg
      this.link = this.searchLink
    }

    if (this.id) {
      Book.getOne(this.id)
        .then((res) => {
          let item = res.data
          this.title = item.title
          this.author = item.author
          this.image = this.getImgUrl(item.image)
          this.link = item.link
          this.status = item.status
          this.selShelves = new Set(item.shelves)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },

  methods: {
    loadShelves() {
      Book.getShelves()
        .then((res) => {
          this.shelves = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    getImgUrl(url) {
      if (!url || url.startsWith('http')) {
        return url
      } else {
        return Config.WS_URL_IMG + url
      }
    },

    onClickShelve(shelve) {
      if (this.selShelves.has(shelve.id)) {
        this.selShelves.delete(shelve.id);
      } else {
        this.selShelves.add(shelve.id);
      }
    },

    onAddShelve() {
      if (this.newShelve && this.shelves.findIndex((s) => s.name == this.newShelve) == -1) {
        Book.postShelve({
        name: this.newShelve,
        tier: 2,
        })
        .then((res) => {
          this.loadShelves()
          this.newShelve = ''
          this.selShelves.add(res.data.id);
          console.log(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
        
      }
      this.newTag = "";
    },

    shelveClasses(shelve) {
      return [
        "tag",
        "is-clickable",
        { "is-primary": this.selShelves.has(shelve.id) },
      ];
    },

    handleSearch() {
      this.searching = true
      axios
        .get(Config.WS_SEARCH + 'book/search?q=' + this.title)
        .then((res) => {
          this.results = res.data
          this.image = this.results[0].img
          this.currentResult = 0
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.searching = false
        })
    },

    handleNextImg() {
      this.currentResult += 1
      if (this.currentResult >= this.results.length) {
        this.currentResult = 0
      }
      this.image = this.results[this.currentResult].img
    },

    handlePrevImg() {
      this.currentResult -= 1
      if (this.currentResult < 0) {
        this.currentResult = this.results.length - 1
      }
      this.image = this.results[this.currentResult].img
    },

    handleDelete() {
      Book.del(this.id)
        .then(() => {
          this.$router.push({ name: 'BookList' })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleCancel() {
      this.$router.push({ name: 'BookList' })
    },

    hasError(id) {
      return this.errors.includes(id)
    },

    handleAdd() {
      this.errors = []
      if (!this.title) {
        this.errors.push('title')
      }
      if (this.selShelves.length == 0) {
        this.errors.push('shelves')
      }

      if (this.errors.length > 0) {
        return
      }

      if (this.id) {
        this.putData()
      } else {
        this.postData()
      }
    },

    postData() {
      Book.post({
        title: this.title,
        author: this.author,
        image: this.image,
        link: this.link,
        status: this.status,
        shelves: [...this.selShelves]
      })
        .then((res) => {
          this.$router.push({ name: 'BookList' })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    putData() {
      Book.put(this.id, {
        title: this.title,
        author: this.author,
        image: this.image,
        link: this.link,
        status: this.status,
        shelves: [...this.selShelves]
      })
        .then((res) => {
          this.$router.push({ name: 'BookList' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style>
@media screen and (max-width: 768px) {
  .fw-mobile {
    width: 100%;
  }
}

.shelves {
  margin-top: 20px;
}

.tags {
  margin-top: 20px;
}
</style>
